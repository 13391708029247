document.addEventListener('DOMContentLoaded', function () {
    const cards = document.querySelectorAll('.S1-Card');

    cards.forEach(card => {
        card.addEventListener('click', function () {
            cards.forEach(otherCard => {
                if (otherCard !== this) {
                    otherCard.classList.remove('active');
                }
            });

            this.classList.toggle('active');
        });
    });
});


function copyText(button) {
    var textToCopy = "0X4bijhfdsuhbfsdgusfdyguydfs7f8sd378238773824uhfuvwfuvfevufeuvfwevuuvfe";

    navigator.clipboard.writeText(textToCopy).then(function() {
        button.textContent = "Copied";
        setTimeout(function() {
            button.textContent = "Copy";
        }, 2000);
    }).catch(function(err) {
        alert("Failed to copy text: " + err);
    });
}