import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { EXRLoader } from "three/examples/jsm/loaders/EXRLoader.js";
import { gsap } from "gsap";

document.addEventListener("DOMContentLoaded", () => {
    AOS.init({
        duration: 1200,
        once: true,
    });

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.getElementById("model-container").appendChild(renderer.domElement);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.6);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.8);
    directionalLight.position.set(5, 10, 7.5);
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    const loader = new GLTFLoader();
    loader.load(
        "https://pub-6e601599197547958ade1d2f311852c1.r2.dev/Sphere.glb",
        function (gltf) {
            const model = gltf.scene;

            const exrLoader = new EXRLoader();
            exrLoader.load("https://pub-6e601599197547958ade1d2f311852c1.r2.dev/Metalic-3D-liquid.exr", (texture) => {
                texture.mapping = THREE.EquirectangularReflectionMapping;
                texture.encoding = THREE.LinearEncoding;

                model.traverse((child) => {
                    if (child.isMesh) {
                        child.material = new THREE.MeshStandardMaterial({
                            envMap: texture,
                            metalness: 2.0,
                            roughness: 0.001,
                        });
                    }
                });
            });

            const pivot = new THREE.Group();
            pivot.add(model);
            scene.add(pivot);

            const boundingBox = new THREE.Box3().setFromObject(model);
            const center = new THREE.Vector3();
            boundingBox.getCenter(center);

            model.position.set(-center.x, -center.y - 2, -center.z);

            const minZ = 5;
            const maxZ = 40;
            const maxModelScale = 10.0;
            const scrollThreshold = 20; // Threshold to enable/disable scrolling
            camera.position.z = maxZ;

            let targetZ = camera.position.z;

            // Smooth zoom effect with continuous scrolling
            function onScroll(event) {
                const zoomSpeed = 0.5;
                const zoomDirection = -Math.sign(event.deltaY); // Invert the scroll direction

                // Adjust the targetZ based on scrolling direction, with smooth increments
                targetZ = Math.max(minZ, Math.min(maxZ, targetZ + zoomSpeed * zoomDirection));

                // Use GSAP's killTweensOf to ensure no conflicting animations
                gsap.killTweensOf(camera.position);

                gsap.to(camera.position, {
                    z: targetZ,
                    duration: 1.0,  // Adjust the duration for smoothness
                    ease: "power3.out",  // Easing for smoother transition
                    onUpdate: () => {
                        const scaleFactor = 1 + (maxZ - camera.position.z) / (maxZ - minZ) * (maxModelScale - 1);
                        model.scale.set(scaleFactor, scaleFactor, scaleFactor);  // Smooth scale transition
                    },
                });

                // Prevent default scrolling if zoomed out beyond the threshold
                if (camera.position.z > scrollThreshold) {
                    event.preventDefault();
                }
            }

            window.addEventListener('wheel', onScroll, { passive: false });

            function animate() {
                requestAnimationFrame(animate);
                pivot.rotation.y += 0.002;
                renderer.render(scene, camera);
            }

            animate();
        },
        undefined,
        function (error) {
            console.error(error);
        }
    );
});
